import * as React from "react";
import { render } from "react-dom";
import { Provider } from "react-redux";
import configureStore from "./store";
import 'react-app-polyfill/ie11';
import './i18n';

import {App} from "./App";

const store = configureStore();
console.log("Version: 1.0.0")
const Root = () => (
  <Provider store={store}>
    <React.Suspense fallback={<div>Loading... </div>}>
        <App />
    </React.Suspense>
  </Provider>
);

render(<Root />, document.getElementById("root"));
